const consentModal = document.querySelector('.consent');
const btnOpenModal = document.querySelector('#openConsentBtn');
const btnAccept = document.querySelector('#consentBtnAccept');
const btnCookieSettings = document.querySelector('#consentBtnCookies');
const btnSaveSettings = document.querySelector('#consentBtnSave');
const cookieCheckbox = document.querySelector('#consentPersonal');

const consentForm1 = document.querySelector('#consentForm1');
const consentForm2 = document.querySelector('#consentForm2');

let consent = localStorage.getItem('consent');

function closeConsentModal() {
    consentModal.classList.add('hidden');
}
function saveConsent() {
    localStorage.setItem('consent', consent);
}
function resetLocalStorage() {
    localStorage.removeItem('theme');
    localStorage.removeItem('consent');
}

const currentUrl = window.location.pathname;
const isImprint = currentUrl === '/imprint.html' || currentUrl === '/datenschutz.html' || currentUrl === '/imprint' || currentUrl === '/datenschutz';

if(consent !== 'true' && consent !== 'false' && !isImprint) {
    consentModal.classList.remove('hidden');
}

btnOpenModal.addEventListener('click', function() {
    resetLocalStorage();
    consentModal.classList.remove('hidden');
})

btnAccept.addEventListener('click', function(e) {
    e.preventDefault();
    consent = true;
    saveConsent();
    closeConsentModal()
});

btnCookieSettings.addEventListener('click', function(e) {
    e.preventDefault();
    consentForm1.classList.add('hidden');
    consentForm2.classList.remove('hidden');
});

btnSaveSettings.addEventListener('click', function(e) {
    e.preventDefault();
    consent = cookieCheckbox.checked;
    if(!consent) resetLocalStorage();
    localStorage.setItem('consent', consent);
    consentForm2.classList.add('hidden');
    consentForm1.classList.remove('hidden');
    consentModal.classList.add('hidden');
});